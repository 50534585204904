
.logo-deelnemers {
    margin-top: 60px;
    a {
        padding: 20px;
        &:hover{
            text-decoration: none;
        }
    }
    .item-mas { 
        border: 1px dotted $oeno-blauw;
        border-radius: 2px;
        text-align: center;
        height: 400px;
        &:hover {
            text-decoration:none;
        }
        &--image {
            width: 80%;
            height: 175px;
            margin: 25px auto 50px auto;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }

        &:hover .item-mas--image {
            width: 90%;
            margin: 15px auto 90px auto; 
            transition: all 2s;
        }
        h3 {
            width: 100%;
            background-color: $oeno-blauw;
            color: #fff;
            padding: 30px 0px;
        }


        img {
            width: 200px;
            margin: 30px auto 60px auto;    
            transition: all 2s;
        }
    }    
}
