.choice-content {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.single {
  .choice-content.alone {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
