.contact-form {
  label {
    font-family: "Roboto Slab", serif !important;
  }
  input,
  textarea {
    border-radius: 0px !important;
    font-family: "Roboto Slab", serif !important;
  }

  .frm_style_formidable-style .with_frm_style .frm_radio {
    display: inline !important;
  }
  .rein-submit {
    background-color: #fff;
    color: #000 !important;
    padding: 10px 10px;
    border-radius: 10px;
    border: 3px solid $oeno-oranje;
    text-align: center;
    width: 180px;
    transition: all 0.2s;
    margin-left: 15px;
    &:hover {
      text-decoration: none;
      background-color: $oeno-oranje;
      color: #fff !important;
      transition: all 0.2s;
    }
  }
}

.contact-form-rij {
  .form-container {
    padding: 30px 15px;
    input[type="text"],
    input[type="email"],
    textarea {
      padding: 5px;
      border: 1px solid #eee;
      width: 100%;
      margin-bottom: 5px;
    }
  }
}
