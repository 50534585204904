.activiteiten-rij {
	margin-bottom: 15px;
	a{
		margin: 15px 0px 0px;
		padding: 0px;
		&:hover {
			text-decoration: none;
		}
		.activiteiten--date-tile {
			position: relative;
			padding: 15px 15px;
			width: 22%;
			height: 150px;
			background-color: $oeno-oranje;
			color: #fff;
			display: inline;
			float: left;
			text-align: center;
			.tijd-regel-onder {
				position: absolute;
				bottom: 0px;
				left: 50%;
				transform: translateX(-50%);
			}
		}
		.activiteiten--img-tile	{
			width: 22%;
			height: 150px;
			display: inline;
			float: left;
			.img-activiteit {
				height: 150px;
				display: block;	
			}
		}
		.activiteiten--tekst-tile {
			padding: 15px;
			width: 56%;
			height: 150px;
			display: inline;
			float: left;
			background-color: #eee;
		}	
	}

}