$oeno-blauw: #0096d1;
$oeno-letter-blauw: #06222d;
$oene-sectie-blauw: #cceaf5;
$oeno-oranje: orange;
$oeno-letter-donker: #333333;
$dark: #111;

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

/**** Fonts ****/

/* Main letter, Google fonts: Roboto slab  */
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400,700");

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

#topper {
  position: fixed;
  border-radius: 3px;
  top: 92%;
  left: 93%;
  background-color: $oeno-oranje;
  padding: 10px 20px;
  z-index: 1;
  color: #fff;
  i {
    font-size: 3rem;
  }
}

.read-on {
  position: relative;
  padding: 5px 60px 5px 20px;

  &--white {
    border: 1px solid #fff;
    color: #fff;
    transition: all 0.3s;
    &:after {
      color: #fff;
    }
  }
  &--hover-white {
    &:hover {
      background-color: #fff;
      color: $oeno-blauw;
      text-decoration: none;
      transition: all 0.3s;
      &:after {
        color: $oeno-blauw;
        transition: all 0.3s;
      }
    }
  }
  &--blue {
    color: #fff !important;
    background-color: $oeno-blauw;
    transition: all 0.2s;
  }
  &--hover-blue {
    &:hover {
      background-color: $dark;
    }
  }
  &--oranje {
    border: 1px solid $oeno-oranje;
    color: $oeno-oranje;
    transition: all 0.3s;
    &:after {
      color: $oeno-oranje;
    }
  }
  &--hover-oranje {
    &:hover {
      background-color: $oeno-oranje;
      color: #fff;
      text-decoration: none;
      transition: all 0.3s;
      &:after {
        color: #fff;
        transition: all 0.3s;
      }
    }
  }

  &:after {
    position: absolute;
    right: 20px;
    font-family: fontawesome;
    content: "\f105";
    transition: all 0.3s;
  }
  &:hover {
    &:after {
      right: 5px;
    }
  }
  &-small {
    padding: 3px 30px 3px 10px;
    font-size: 0.6em;
    &:after {
      top: -6px;
    }
  }
  &-big {
    padding: 10px 75px 10px 30px;
    font-size: 1.6em;
    display: inline-block;
  }
}

.background-base {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.h-align-left {
  text-align: left;
}
.h-align-center {
  text-align: center;
}
.h-align-right {
  text-align: right;
}

.frame-tertiary {
  position: relative;
  z-index: 1;
  transition: all 0.2s;
  &:after {
    position: absolute;
    z-index: -1;
    content: " ";
    right: 0;
    top: -5px;
    width: 90%;
    height: 90%;
    max-width: 380px;
    background-color: $oeno-oranje;
  }
}
.frame-tertiary-large {
  @extend .frame-tertiary;
  z-index: 4;
  &:after {
    z-index: -1;
    max-width: unset;
    width: 90%;
    height: 100%;
    top: -21px;
    right: -7px;
  }
}

.social-row {
  &__item {
    width: 30px;
    height: 30px;
    background-color: $oeno-blauw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 0 0.5rem;
    transition: all 0.2s;
    &:hover {
      text-decoration: none;
      background-color: $dark;
    }
  }
}

#publications {
  i {
    width: 25px;
    height: 25px;
    // color: #fff;
    border-radius: 500px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    background-color: $oeno-blauw;
  }
}

.single-content + .rij-content-blokken {
  padding-top: 4rem !important;
}

.choice-content + .choice-image.order-1 {
  margin-bottom: 3rem;
  @include atSmall {
    margin-bottom: unset;
  }
}

.choice-image.order-1 + .choice-content.order-2 {
  margin-top: 3rem;
  @include atSmall {
    margin-top: unset;
  }
}

.hero-algemeen + .container + .rij-content-blokken {
  margin-top: 3rem;
  @include atSmall {
    margin-top: unset;
  }
}
