.youtube-grid {
	margin: 60px 0px;
	&--inleiding {
		margin: 30px 0px;
	}
	&--tegel-outer{
		padding: 15px;
	}
	&--tegel-inner {
		border: 1px dotted $oeno-blauw;
		border-radius: 2px;
		padding: 15px;
		height: unset;
		overflow: hidden;
		@include atSmall {
			height: 500px;		
		}
		.titel {
			height: 100px;
			color: $oeno-blauw;
		}
	}
}