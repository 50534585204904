* {
  font-family: "Roboto Slab", serif;
  line-height: 3rem;
  box-sizing: border-box;
}

textarea,
button,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
}

.btn-inschrijven,
.btn-meer {
  background-color: #fff;
  color: #000 !important;
  padding: 10px 10px;
  border-radius: 10px;
  border: 3px solid $oeno-oranje;
  text-align: center;
  width: 180px;
  transition: all 0.2s;
  &:hover {
    text-decoration: none;
    background-color: $oeno-oranje;
    color: #fff !important;
    transition: all 0.2s;
  }
}

a.sluiten {
  color: $oeno-oranje;
  font-size: 3rem;
}

.btn-inschrijven,
.btn-meer {
  @include atSmall {
    padding: 10px 80px;
    border-radius: 10px;
  }
}

.cta-front {
  position: relative;
  width: 20%;
  height: 20px;
  top: -15px;
  margin: 0 auto;
  z-index: 5;
}

#return-to-top {
  position: fixed;
  z-index: 100;
  bottom: 20px;
  right: 20px;
  background: $oeno-oranje;
  width: 50px;
  height: 50px;
  display: block;
  text-decoration: none;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  display: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 16px;
  top: 10px;
  font-size: 29px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#return-to-top:hover {
  background: rgba(0, 0, 0, 0.9);
}
#return-to-top:hover i {
  color: #fff;
  top: 5px;
}

.search-excerpt {
  color: $oeno-blauw;
  background-color: #eee;
}

hr {
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}

blockquote {
  border-left: 5px solid $oeno-oranje !important;
}
