$p1: 0.5rem;
$p2: 0.75rem;
$p3: 1rem;
$p4: 2rem;
$p5: 5rem;

.bg-white {
  background-color: #fff;
}
.bg-secondary {
  background-color: $oene-sectie-blauw;
}
.bg-light {
  background-color: #eee;
}
.m-0 {
  margin: 0 !important;
}
.mb-1 {
  margin-bottom: $p1 !important;
}
.mb-2 {
  margin-bottom: $p2 !important;
}
.mb-3 {
  margin-bottom: $p3 !important;
}
.mb-4 {
  margin-bottom: $p4 !important;
}
.mb-5 {
  margin-bottom: $p5 !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: $p1 !important;
}
.mt-2 {
  margin-top: $p2 !important;
}
.mt-3 {
  margin-top: $p3 !important;
}
.mt-4 {
  margin-top: $p4 !important;
}
.mt-5 {
  margin-top: $p5 !important;
}

.mb-sm-0 {
  margin-bottom: inherit;
  @include atSmall {
    margin-bottom: 0 !important;
  }
}
.mb-0 {
  margin-bottom: 0;
}
.ms-1 {
  margin-left: $p1;
}
.ms-2 {
  margin-left: $p2;
}
.ms-3 {
  margin-left: $p3;
}
.ms-4 {
  margin-left: $p4;
}
.ms-5 {
  margin-left: $p5;
}
.me-0 {
  margin-right: 0;
}
.me-1 {
  margin-right: $p1;
}
.me-2 {
  margin-right: $p2;
}
.me-3 {
  margin-right: $p3;
}
.me-4 {
  margin-right: $p4;
}
.me-5 {
  margin-right: $p5;
}
.mb-2 {
  margin-bottom: $p2;
}
.mb-3 {
  margin-bottom: $p3;
}
.mb-4 {
  margin-bottom: $p4;
}
.mb-5 {
  margin-bottom: $p5;
}
.mt-2 {
  margin-top: $p2;
}
.mt-3 {
  margin-top: $p3;
}
.mt-4 {
  margin-top: $p4;
}
.mt-5 {
  margin-top: $p5;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mx-1 {
  margin-left: $p1 !important;
  margin-right: $p1 !important;
}
.mx-2 {
  margin-left: $p2 !important;
  margin-right: $p2 !important;
}
.mx-3 {
  margin-left: $p3 !important;
  margin-right: $p3 !important;
}
.mx-4 {
  margin-left: $p4 !important;
  margin-right: $p4 !important;
}
.mx-5 {
  margin-left: $p5 !important;
  margin-right: $p5 !important;
}
.mx-sm-0 {
  @include atSmall {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.mx-sm-1 {
  @include atSmall {
    margin-left: $p1 !important;
    margin-right: $p1 !important;
  }
}
.mx-sm-2 {
  @include atSmall {
    margin-left: $p2 !important;
    margin-right: $p2 !important;
  }
}
.mx-sm-3 {
  @include atSmall {
    margin-left: $p3 !important;
    margin-right: $p3 !important;
  }
}
.mx-sm-4 {
  @include atSmall {
    margin-left: $p4 !important;
    margin-right: $p4 !important;
  }
}
.mx-sm-5 {
  @include atSmall {
    margin-left: $p5 !important;
    margin-right: $p5 !important;
  }
}
.mt-sm-0 {
  @include atSmall {
    margin-top: 0 !important;
  }
}
.mb-sm-0 {
  @include atSmall {
    margin-bottom: 0 !important;
  }
}
.my-1 {
  margin-top: $p1;
  margin-bottom: $p1;
}
.my-2 {
  margin-top: $p2;
  margin-bottom: $p2;
}
.my-3 {
  margin-top: $p3;
  margin-bottom: $p3;
}
.my-4 {
  margin-top: $p4;
  margin-bottom: $p4;
}
.my-5 {
  margin-top: $p5 !important;
  margin-bottom: $p5 !important;
}
.p-0 {
  padding: 0;
}
.p-1 {
  padding: $p1;
}
.py-1 {
  padding-top: $p1 !important;
  padding-bottom: $p1 !important;
}
.p-2 {
  padding: $p2 !important;
}
.py-2 {
  padding-top: $p2 !important;
  padding-bottom: $p2 !important;
}
.p-3 {
  padding: $p3;
}
.py-3 {
  padding-top: $p3 !important;
  padding-bottom: $p3 !important;
}
.p-4 {
  padding: $p4;
}
.py-4 {
  padding-top: $p4 !important;
  padding-bottom: $p4 !important;
}
.p-5 {
  padding: $p5;
}
.py-5 {
  padding-top: $p5 !important;
  padding-bottom: $p5 !important;
}
.py-sm-5 {
  @include atSmall {
    padding-top: $p5 !important;
    padding-bottom: $p5 !important;
  }
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-1 {
  padding-left: $p1 !important;
  padding-right: $p1 !important;
}
.px-2 {
  padding-left: $p2 !important;
  padding-right: $p2 !important;
}
.px-3 {
  padding-left: $p3 !important;
  padding-right: $p3 !important;
}
.px-4 {
  padding-left: $p4 !important;
  padding-right: $p4 !important;
}
.px-5 {
  padding-left: $p5 !important;
  padding-right: $p5 !important;
}

.px-sm-0 {
  @include atSmall {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.px-sm-1 {
  @include atSmall {
    padding-left: $p1 !important;
    padding-right: $p1 !important;
  }
}
.px-sm-2 {
  @include atSmall {
    padding-left: $p2 !important;
    padding-right: $p2 !important;
  }
}
.px-sm-3 {
  @include atSmall {
    padding-left: $p3 !important;
    padding-right: $p3 !important;
  }
}
.px-sm-4 {
  @include atSmall {
    padding-left: $p4 !important;
    padding-right: $p4 !important;
  }
}
.px-sm-5 {
  @include atSmall {
    padding-left: $p5 !important;
    padding-right: $p5 !important;
  }
}
.pb-0 {
  padding-top: unset !important;

  padding-bottom: 0 !important;
}
.pb-1 {
  padding-top: unset !important;

  padding-bottom: $p1 !important;
}
.pb-2 {
  padding-top: unset !important;

  padding-bottom: $p2 !important;
}
.pb-3 {
  padding-top: unset !important;

  padding-bottom: $p3 !important;
}
.pb-4 {
  padding-top: unset !important;

  padding-bottom: $p4 !important;
}
.pb-5 {
  padding-top: unset !important;
  padding-bottom: $p5 !important;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-inline-block {
  display: inline-block;
}
.d-none {
  display: none;
}
.d-sm-none {
  @include atSmall() {
    display: none !important;
  }
}
.d-sm-block {
  @include atSmall() {
    display: block !important;
  }
}
.d-sm-flex {
  @include atSmall {
    display: flex !important;
  }
}
.d-md-flex {
  @include atMedium {
    display: flex !important;
  }
}
.text-underline {
  text-decoration: underline;
}
.text-white {
  color: #fff !important;
}
.text-dark {
  color: $oeno-letter-blauw !important;
}
.text-secondary {
  color: $oeno-blauw;
}
.text-small {
  font-size: 0.7em !important;
}
.text-black {
  color: $dark;
}
.text-center {
  text-align: center;
}
.text-decoration-none {
  text-decoration: none;
  &:hover,
  &:focus,
  &:visited {
    text-decoration: none;
  }
}
.list-style-none {
  list-style: none;
}
.fw-bold {
  font-weight: bold !important;
}
.border-0 {
  border: 0px solid #fff !important;
}
.box-shadow-0 {
  box-shadow: none !important;
}
.radius-0 {
  border-radius: 0px !important;
}

.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute;
}
.w-100 {
  width: 100%;
}
.w-100-important {
  width: 100% !important;
}
.w-sm-96 {
  @include atSmall {
    width: 96% !important;
  }
}
.h-100 {
  height: 100% !important;
}

.align-items-center {
  align-items: center !important;
}
.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}

.flex-column {
  flex-direction: column !important;
}
.flex-sm-row {
  @include atSmall {
    flex-direction: row !important;
  }
}

.btn-oeno-primary {
  color: #fff !important;
  background-color: $oeno-blauw;
  transition: all 0.2s;
  &:hover {
    background-color: $dark;
  }
}
.btn-oeno-tertiary {
  color: #fff !important;
  background-color: $oeno-oranje;
  transition: all 0.2s;
  &:hover {
    background-color: $dark;
  }
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-sm-init {
  @include atSmall {
    order: unset !important;
  }
}

.w-sm-48 {
  @include atSmall {
    width: 46%;
  }
}

.row.d-flex {
  @include atSmall {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
}
