

.verborgen-meerover {
	margin: 0px 0px;
	height: 0px; 
	overflow: hidden;
	background-color: $oene-sectie-blauw;
}

.meerover-open {
	padding: 90px 0px 15px 0px;
	height: auto !important;
}

.oeno-sprekers {
	padding: 60px 0px 30px 0px;
	text-align: center;
	.flex-item{
		width: 175px;
	}
	.flex-container {
		display: flex;
		flex-flow: row wrap;	  
		-webkit-flex-flow: row wrap;
		justify-content: space-around;		
	}
	&--tile {
		margin-bottom: 15px;
		@include atMedium {
			margin-bottom: 0px;
		}
		p{
			margin-top: 15px;
			font-weight: bold;
			span {
				font-style: italic;
				font-weight: lighter;
			}
		}
		&-img {
			height: 100px;
			width: 100px;
			border-radius: 100%;
			border: 1px solid $oeno-oranje;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			margin: 0 auto;	
			@include atSmall{
				height: 175px;
				width: 175px;
			}
			&-sprekersklein {
				height: 100px;
				width: 100px;
				border-radius: 100%;
				margin: 15px auto;	
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;	
				@include atSmall{
					height: 125px;
					width: 125px;
				}	
			}
		}
	}
	&--tile
	h2{
		margin: 30px 0px 60px 0px;
			&:before {
			font-family: FontAwesome;
			content: "\f075";
			position: relative;
			left: -15px;
			color: $oeno-oranje;
		}
	}
	.sub-sprekers {
		margin: 30px 0px;
		max-height: 220px;
		overflow: hidden;
		position: relative;
		transition: all .4s;
		@include atSmall {
			max-height: 235px;
		}
		a.arrow {
			position: absolute;
			left: 49%;
			bottom: -10px;
			color: $oeno-oranje;
			z-index: 6;
			i{
				font-size: 4rem;
			}
		}
	}

	.arrowopen {
		max-height: 1625px;
		transition: all .6s;
		a.arrow {
			transform: rotate(180deg);
		}
	}
}
.oeno-programma {

	padding: 60px 0px;

	h2 {
		margin: 0px 0px 30px 0px;
		text-align: center;
		&:before {
			font-family: FontAwesome;
			content: "\f073";
			position: relative;
			left: -15px;
			color: $oeno-oranje;
		}
	}
	&--rij {
		padding: 15px 0px;
	}
	&--item {
		margin: 0px -15px;
		&:nth-child(odd),
		&:last-child {
			background-color: #eee;
		}
	}
}

#over { 
	background-color: $oene-sectie-blauw;

	.oeno-over {
		position: relative;
		min-height: 400px;

		&--content {
			padding: 30px 15px 15px 15px;
			h2{
				&:before {
					font-family: FontAwesome;
					content: "\f05a";
					color: $oeno-oranje;
					position: relative;
					margin-right: 30px;
				}
			}

			@include atMedium {
				padding: 30px 0px;
			}
		} 
		i{
			color: $oeno-oranje;
		}
		&--afbeelding {
			margin: 60px;
			display:block; 
			height: 300px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			box-shadow: 15px -15px $oeno-oranje;
		}
	}
}

#partners {
	position: relative;

	#deelnemerCarousel {
		min-height: 300px;
		position: relative;
		margin-top:30px;
		.carousel-inner {
			.item {
				.logo-deelnemer {
					display: flex;
				  	align-items: center;
		  			justify-content: center;
					min-height: 200px;
					text-align: center;
					max-width: 50%;
					img{
						width: 150px;
					}
				}
			}
		}
	}
	.carousel-indicators {
		position: relative;
		z-index: 1;
		li {
			background-color: $oeno-oranje !important;

		}
	}

	h2 {
		&:before {
			content: "&";
			position: relative;
			left: -15px;
			top: 6px;
			color: $oeno-oranje;
			font-weight: bold;
			font-size: 5rem;
		}		
	}
		.oeno-partner {

		&--knop{
			display: none;
			position: relative;
			top: 40px;
			i{
				font-size: 5rem;
				color: $oeno-oranje;
				font-weight: lighter;
			}
			@include atMedium {
				display: unset;
			}
		}

		&--meer-partners {
			width: 100%;
			text-align: center;
			padding: 10px 0px;
			margin: 60px 0px 30px 0px;
			display: inline-block;	
			a{
				text-decoration: none;
				color: $oeno-letter-donker;
			}
		}
		&--intro {
			text-align: center;
		}
		&--row{
			display: flex;
			margin: 30px 0px;
		}
		&--logo {
			flex: 1;
			text-align: center;
		}
	}
}

#contact {
	padding: 30px 15px;
	.oeno-contact {
		&--colapser {
			margin-top: 15px;
			padding: 25px;
			background: #eee;

			span {
				font-weight: lighter;
				:after{
					content:"";
				}

			}
		}

		@include atMedium {
			padding: 30px 0px;
		}
		
		&--vraag{
			border-bottom: 3px solid #eee;
			padding: 20px 0px;
			text-align: left;

			a.collapsed {
				text-align: left;
				&:hover{
					font-weight: bold;
					cursor: pointer;
					text-decoration: none;
				}
				&:active,
				&:focus {
					text-decoration: none;
				}
			}
			a.collapsed:after {
				font-family: Fontawesome;
				content: "\f103";
				position: absolute;
				right: 10px;
				color: $oeno-oranje;
				font-size: 2.5rem;

			}
			&:hover{
				font-weight: bold;
				cursor: pointer;
				text-decoration: none;
			}
			&:active,
			&:focus {
				text-decoration: none;
			}
			a:after {
				font-family: Fontawesome;
				content: "\f102";
				position: absolute;
				left: -30px;
				color: $oeno-oranje;
				font-size: 2.5rem;				
			}
		}
		a.andere-vraag {
			color: $oeno-oranje;
			&:after {
				font-family: Fontawesome;
				content: "\f129";
				position: absolute;
				right: 12px;
				color: $oeno-oranje;
				font-size: 2.5rem;						
			}
		}

		&--mail {
			margin-top: 60px;
		}
		&--mail-tile {
			box-sizing: border-box;
			padding: 15px;
			margin: 30px 0px;
			&-border{
				border: 4px dashed $oeno-oranje;
			}
		}
	}
}



