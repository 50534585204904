.navigatie {
  position: fixed;
  z-index: 10;
  width: 100%;
  &-opened {
    height: 100%;
    overflow-y: auto;
    @include atSmall {
      overflow-y: unset;
      height: auto;
    }
  }
}

.nav-oeno {
  background-color: $oeno-blauw;
  color: #fff;
  border-radius: 0px !important;
  margin-bottom: 0px !important;

  .navbar-header,
  .navbar-nav {
    padding: 0 0px;
    @include atSmall {
      padding: 15px 0px;
    }
  }
  .navbar-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      order: 2;
    }
    .navbar-brand {
      order: 1;
    }
    @include atSmall {
      display: block;
    }
    &::before {
      display: none;
      content: "";
      @include atSmall {
        display: table;
        content: " ";
      }
    }
    &::after {
      display: none;
      @include atSmall {
        display: block;
      }
    }
  }
  li a {
    color: #fff;
    font-size: 2rem;
    @include atSmall {
      font-size: unset;
    }
  }

  .nav > li > a:hover {
    background-color: unset;
    color: $oeno-oranje;
  }
  .nav > li > a:focus {
    background-color: unset;
  }

  .btn-nav {
    margin-left: 0px;

    @include atMedium {
      margin-left: 60px;
    }
  }
  .navbar-toggle {
    .icon-bar {
      background-color: $oeno-oranje;
      height: 3px;
      width: 40px;
      margin-bottom: 8px;
    }
  }
  .navbar-brand {
    float: none;
    padding: unset;
    height: unset;
    @include atSmall {
      float: left;
      height: 50px;
      padding: 15px 15px;
    }
    img {
      position: relative;
      width: 100px;
      top: 0;
      @include atXsmall {
        width: 180px;
        top: -13px;
      }
      @include atMedium {
        width: 120px;
        top: -12px;
        position: relative;
      }
    }
  }
  .sub-menu {
    display: none;
    border: none;
    li a {
      color: #fff;
      border-left: 3px solid $oeno-oranje;
    }
    @include atSmall {
      margin-top: 30px;
      border-radius: 0px 20px;
      float: right;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 15px;
      li a {
        color: $oeno-blauw;
      }
    }
  }

  .navbar-right .dropdown-menu {
    right: unset;
  }
  .menu-item-has-children {
    position: relative;
    margin-right: 15px;
  }

  .menu-item-has-children:after {
    position: absolute;
    font-family: fontawesome;
    content: "\f107";
    right: unset;
    left: -2px;
    top: 5px;
    color: $oeno-oranje;
    @include atSmall {
      right: 0px;
      top: 10px;
      left: unset;
    }
  }
  .current_page_item,
  .current-menu-item {
    a {
      color: $oeno-oranje;
    }
  }
}

/* Icon 1 */

#nav-icon1,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
  width: 60px;
  height: 45px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  transform: rotate(180deg);
}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 9px;
  width: 100%;
  background: #fff;
  // border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

/* Icon 2 */

#nav-icon2 {
}

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 4px;
  width: 70%;
  background: #fff;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  border-radius: 30px;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
  display: none;
}

#nav-icon2 span:nth-child(odd) {
  left: 0px;
}

#nav-icon2 span:nth-child(1),
#nav-icon2 span:nth-child(2) {
  top: 5px;
}

#nav-icon2 span:nth-child(3),
#nav-icon2 span:nth-child(4) {
  top: 18px;
}

#nav-icon2 span:nth-child(5),
#nav-icon2 span:nth-child(6) {
  top: 31px;
}

#nav-icon2.open span:nth-child(1),
#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),
#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 29px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 29px;
}
li.toolbox-menu-item a:after {
  font-family: fontawesome;
  content: "\f107";
  position: relative;
  margin-left: 1rem;
  top: 1px;
}
li.toolbox-menu-item.opened a {
  color: lighten($oeno-oranje, 30);
  &:after {
    content: "\f106";
  }
}
#mega-menu {
  background-color: #fff;
  box-shadow: 0 3px 7px -1px #aaa;
}
.mobile-mega {
  position: relative !important;
  overflow-y: auto;
  color: #fff !important;
  z-index: 99;
  background-color: $oeno-blauw !important;
  box-shadow: none !important;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  margin-bottom: 2rem;
  i {
    font-size: 2rem;
  }
  a {
    color: #fff !important;
  }
}
