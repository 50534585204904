.footer-bar {
  padding: 60px 15px;
  background-color: $oeno-blauw;
  position: relative;
  overflow: hidden;
  img {
    width: 120px;
    @include atSmall {
      width: 200px;
    }
  }
  h4 {
    font-size: 2.4rem;
  }
  .over-footer {
    color: #fff;
    text-align: left;
  }

  .menu-footer {
    color: #fff;
    text-align: left;
    ul {
      padding: 0px;
      text-align: left;
      li {
        list-style: none;
        a {
          color: #fff;
          font-weight: 200;
        }
      }
    }
  }
  .zoeken-footer h4 {
    color: #fff;
  }
  .footer-search {
    margin-top: 30px;
    input {
      border: none;
      padding: 5px 15px;
      width: 90%;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      position: relative;
    }
    i {
      position: relative;
      color: $oeno-blauw;
      right: 35px;
      top: 2px;
      font-size: 2.4rem;
    }
  }
}

.footer-bottom {
  text-align: center;
  background-color: $oeno-blauw;
  color: #fff;
  margin-bottom: 0px;
  a {
    color: #fff;
  }
  p {
    margin-bottom: 0px;
  }
}

.footer-bar:before {
  content: " ";
  height: 400px;
  width: 100vw;
  position: absolute;
  left: 50vw;
  bottom: 30px;
  opacity: 0.1;
}
