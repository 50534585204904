.index-page-head {
  position: relative;
  height: 300px;
  /* background-image: url('https://placeholdit.imgix.net/~text?txtsize=30&txt=[Placeholder+Image]&w=250&h=600'); */
  /* background-position: center center; */
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  overflow: hidden;

  .front-page-head--tile-row {
    position: relative;
    top: 0px;
    height: 300px;
  }
  .front-page-head--tile-row:nth-child(2) {
    display: none;
  }
  &--tile-img {
    height: 300px;
    background-size: cover;
    background-position: center center;
    @include atSmall {
      height: 300px;
    }
  }
  &--transp-layer {
    position: absolute;
    top: 0px;
    height: 300px;
    width: 100%;
    background-color: rgba(255, 165, 0, 0.7);
    z-index: 1;
  }
  .header-overlay {
    position: absolute;
    bottom: 0px;
    width: 100%;
    margin-left: -15px;
    z-index: 2;

    &--content {
      position: relative;
      background-color: rgba(0, 50, 91, 0.8);
      color: #fff;

      padding: 30px 0px 30px 0px;
      @include atMedium {
        left: 0px;
        padding: 15px 0px 60px 0px;
      }

      p {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }
    &--cta {
      position: relative;
      bottom: -15px;
      width: 100%;
      text-align: center;
    }
  }
  &--counter-row {
    position: relative;
    top: -125px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include atXsmall {
      top: -175px;
    }
    @include atMedium {
      top: -160px;
    }
    .counter {
      margin-right: 5px;
      @include atSmall {
        margin-right: 20px;
      }
    }
    .clock {
      text-align: center;
      cursor: default;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-right: 20px;
    }

    .clock-block {
      display: inline-block;
      vertical-align: top;
      position: relative;
      width: 20px;
      height: 50px;
      margin: 0 0px;
      padding-top: 10px;
      line-height: 1;
      font-family: Helvetica, Arial, sans-serif;
      text-align: center;
      text-shadow: 0 1px rgba(255, 255, 255, 0.3);
      background: #e5e7eb;
      border: 1px solid #9fa2ad;
      border-radius: 3px 3px 2px 2px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background-image: -webkit-linear-gradient(top, #f1f2f5, #e5e7eb 20%, #c2c5d1 50%, #e5e7eb 80%, #e4e5eb);
      background-image: -moz-linear-gradient(top, #f1f2f5, #e5e7eb 20%, #c2c5d1 50%, #e5e7eb 80%, #e4e5eb);
      background-image: -o-linear-gradient(top, #f1f2f5, #e5e7eb 20%, #c2c5d1 50%, #e5e7eb 80%, #e4e5eb);
      background-image: linear-gradient(to bottom, #f1f2f5, #e5e7eb 20%, #c2c5d1 50%, #e5e7eb 80%, #e4e5eb);
      -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.1);
      @include atXsmall {
        width: 40px;
        height: 60px;
        padding-top: 10px;
        margin: 0px 6px;
      }
      @include atMedium {
        width: 80px;
        height: 80px;
        padding-top: 20px;
        margin: 0px 8px;
      }
    }

    .clock-block:before,
    .clock-block:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: -1px;
      right: -1px;
      z-index: -1;
      height: 4px;
      background: #cacfd9;
      border: 1px solid #9fa2ad;
      border-radius: 2px;
      -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.4), 0 1px 2px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.4), 0 1px 2px rgba(0, 0, 0, 0.1);
      @include atSmall {
        bottom: -4px;
      }
    }

    .clock-block:before {
      bottom: 0px;
      @include atSmall {
        bottom: -7px;
      }
    }

    .clock-val {
      display: block;
      position: relative;
      z-index: 1;
      font-size: 28px;
      font-weight: bold;
      color: #4f5258;

      @include atXsmall {
        font-size: 32px;
      }
      @include atMedium {
        font-size: 64px;
      }
    }

    .clock-val:before,
    .clock-val:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
    }

    .clock-val:before {
      z-index: -1;
      top: -16px;
      bottom: 50%;
      border-radius: 2px 2px 0 0;
      -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
    }

    .clock-val:after {
      top: 50%;
      bottom: -16px;
      border-top: 1px solid rgba(60, 62, 68, 0.4);
      border-radius: 0 0 2px 2px;
      background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0) 50%);
      background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0) 50%);
      background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0) 50%);
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0) 50%);
      -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
      box-shadow: inset 0 1px rgba(255, 255, 255, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
    }

    .clock-unit {
      display: block;
      margin-top: -2px;
      font-size: 13px;
      font-weight: 200;
      color: #6b707a;
      text-transform: capitalize;
    }

    .clock-unit:after {
      content: "";
      position: absolute;
      z-index: -2;
      top: 100%;
      left: -1px;
      right: -1px;
      height: 30px;
      margin-top: 6px;
      border-radius: 0 0 3px 3px;
      background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0));
      background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0));
      background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0));
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0));
    }
  }
}

.nieuwsoverzicht {
  margin: 30px 0px;
  &--afbeelding-kop {
    display: block;
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 4rem !important;
  }
  .post-meta {
    position: relative;
    color: #bbb;
    margin-bottom: 1rem;
    width: 100%;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    a.cat-overzicht:link,
    a.cat-overzicht:visited {
      float: right;
      position: relative;
      top: -27px;
      color: orange;
      border: 1px solid orange;
      padding: 0px 10px;
      border-radius: 20px;
      margin-left: 5px;
      font-size: 1rem;
      transition: all 0.2s;

      &:hover {
        background-color: $oeno-oranje;
        color: #fff;
        text-decoration: none;
        transition: all 0.2s;
      }
    }
  }
  .style-before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    font-size: 40rem;
    font-weight: bold;
    opacity: 0.1;
    color: #bbb;
    padding: 25% 0 0 0;
  }
  &--cat-overzicht {
    display: block;
    background-color: #eee;
    padding: 30px;
    margin-bottom: 30px;
    position: relative;
    ul {
      padding: 0px;
      li {
        list-style: none;
        a {
          position: relative;
          border-bottom: 1px dotted #ccc;
          display: block;
          color: initial;
          padding: 5px 15px;
          &:before {
            font-family: fontawesome;
            content: "\f105";
            position: absolute;
            left: 0px;
            color: $oeno-oranje;
            transition: 0.3s;
          }
          &:hover {
            text-decoration: none;
            &:before {
              left: 3px;
              transition: 0.3s;
            }
          }
        }
      }
      li:first-child a {
        border-top: 1px dotted #ccc;
      }
    }
  }
  &--overig-overzicht {
    display: block;
    background-color: #eee;
    padding: 30px;
    margin-bottom: 30px;
    position: relative;

    .overzicht-tile {
      position: relative;
      display: block;
      height: 75px;
      margin-bottom: 5px;
      padding: 0px 15px;
      color: inherit;
      transition: 0.3s;
      .overzicht-img {
        height: 75px;
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .overzicht-titel {
        height: 75px;
        position: relative;
        .content-link {
          position: absolute;
          bottom: 40%;
          transform: translateY(50%);
        }
      }
      &:hover {
        background-color: #ddd;
        transition: 0.3s;
      }
    }
  }
  .index-post {
    margin-bottom: 30px;
  }
  .sub-post,
  .head-post {
    h2 {
      margin-top: 30px;
      background-color: $oeno-blauw;
      display: inline-block;
      padding: 3px 15px;
      color: #fff;
    }
  }
  .sub-post h2 {
    font-size: 1.4rem;
  }
}
.nieuwsoverzicht__other-cats {
  @extend .nieuwsoverzicht--cat-overzicht
}
.nav-links {
  a.page-numbers:visited,
  a.page-numbers:link,
  a.page-numbers,
  span.page-numbers {
    padding: 15px;
    border: 1px solid $oeno-oranje;
    margin: auto;
    transition: all 0.3s;
  }
  .current {
    background-color: $oeno-oranje;
    color: $oeno-blauw;
  }
  a.page-numbers:hover {
    text-decoration: none;
    background-color: #eee;
    transition: all 0.3s;
  }
}
