.panel-group {
  margin-bottom: 0 !important;
  .panel {
    display: block;
    margin-bottom: -3px;
    + .panel {
      margin-top: 2px !important;
    }
  }
  .panel-title:before {
    position: absolute;
    font-family: fontawesome;
    right: 0;
    top: 0;
    content: "\f107";
    font-size: 2.375rem;
    transition: all 0.1s;
  }
  a[aria-expanded="true"] .panel-title:before {
    transform: rotate(-180deg);
  }
}
