.hero-algemeen {
  position: relative;
  min-height: 225px;
  display: flex;
  align-items: top;
  &--content {
    background-color: rgba(0, 0, 0, 0.44);
    color: #fff;
    padding: 30px;
    margin: 60px 0px;
    width: unset;
    min-width: 90vw;
    @include atSmall {
      width: 50%;
      min-width: unset;
    }
  }
  .breadcrumb2 {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    background-color: $oeno-blauw;
  }
}

section#top + .pb-4 {
  margin-top: 5rem;
  // background-color: lightpink;
}
