$xsmall-width: 540px;
$small-width: 720px;
$medium-width: 960px;
$large-width: 1140px;

@mixin atXsmall {
	@media screen and (min-width: #{$xsmall-width}) {
		@content;
	} 	
}

@mixin atSmall {
	@media screen and (min-width: #{$small-width}) {
		@content;
	} 	
}

@mixin atMedium {
	@media screen and (min-width: #{$medium-width}) {
		@content;
	} 	
}

@mixin atLarge {
	@media screen and (min-width: #{$large-width}) {
		@content;
	} 	
}



@media screen and (min-width: 576px) {} /* Extra small, style voor alles hierboven */

@media screen and (max-width: 540px) {} /* Small, col-sm style voor alles hieronder */
@media screen and (max-width: 720px) {} /* Medium, col-md style voor alles hieronder*/
@media screen and (max-width: 960px) {} /* Large, col-lg style voor alles hieronder */
@media screen and (max-width: 1140px) {} /* Extra large, col-xl style voor alles hieronder */