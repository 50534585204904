.hero-content {
  margin-top: 60px;
  background-color: rgba(100, 100, 100, 0.4);
  padding: 30px 60px;
  color: #fff;
}

.front-page-head {
  height: 450px !important;
}
.selectie-rij {
  display: block;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 45px;
  padding-bottom: 45px;
}
/*
.selectie-rij:nth-child(even) {
  background-color: #f8f8f8;
}
*/
.rij-content-foto {
  &--image {
    height: 400px;
    width: 300px;
    margin: 30px auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.rij-content-blokken {
  .keuze-afbeelding {
    display: block;
    height: 300px;
  }
  .keuze-youtube {
    iframe {
      max-width: 100% !important;
    }
  }
}

.rij-titel {
  color: $oeno-oranje;
  text-align: center;
}

.rij-info-image-carousel {
  .carousel-rij {
  }
  /* Main carousel style */
  .carousel {
    width: 100%;
    height: 600px;
  }

  /* Indicators list style */
  .article-slide ol.carousel-indicators {
    bottom: 0;
    left: 0px;
    margin-left: 0px;
    width: 100%;
    position: relative;
    top: 1px;
    display: flex !important;
    flex-wrap: wrap;
    padding: 0px;
    z-index: 0;
  }
  /* Indicators list style */
  .article-slide .carousel-indicators li {
    border-radius: 0;
    border: none;
    float: left;
    height: 75px;
    margin: 0px 1px 5px 1px;
    flex: 1 0 21%;
  }

  .carousel-indicators li div {
    filter: opacity(0.5);
  }

  .carousel-indicators li.active div {
    filter: unset;
  }
  .cont-slider {
    padding: 1px;
    margin-top: 15px;
  }
  .afbeelding-carousel-indicatie-afbeelding {
    height: 100px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.carousel-showsixmoveone {
  .carousel-control {
    width: 4%;
    background-image: none;

    &.left {
    }

    &.right {
    }
  }

  .cloneditem-1,
  .cloneditem-2,
  .cloneditem-3,
  .cloneditem-4,
  .cloneditem-5 {
    display: none;
  }

  .carousel-inner {
    @media all and (min-width: 768px) {
      @media (transform-3d), (-webkit-transform-3d) {
        > .item.active.right,
        > .item.next {
          transform: translate3d(50%, 0, 0);
          left: 0;
        }

        > .item.active.left,
        > .item.prev {
          transform: translate3d(-50%, 0, 0);
          left: 0;
        }

        > .item.left,
        > .item.prev.right,
        > .item.active {
          transform: translate3d(0, 0, 0);
          left: 0;
        }
      }

      > .active.left,
      > .prev {
        left: -25%;
      }

      > .active.right,
      > .next {
        left: 25%;
      }

      > .left,
      > .prev.right,
      > .active {
        left: 0;
      }

      .cloneditem-1,
      .cloneditem-2,
      .cloneditem-3 {
        display: block;
      }
    }

    @media all and (min-width: 992px) {
      @media (transform-3d), (-webkit-transform-3d) {
        > .item.active.right,
        > .item.next {
          transform: translate3d(25%, 0, 0);
          left: 0;
        }

        > .item.active.left,
        > .item.prev {
          transform: translate3d(-25%, 0, 0);
          left: 0;
        }

        > .item.left,
        > .item.prev.right,
        > .item.active {
          transform: translate3d(0, 0, 0);
          left: 0;
        }
      }

      > .active.left,
      > .prev {
        left: -12.5%;
      }

      > .active.right,
      > .next {
        left: 12.5%;
      }

      > .left,
      > .prev.right,
      > .active {
        left: 0;
      }

      .cloneditem-4,
      .cloneditem-5 {
        display: block;
      }
    }
  }
}

/* START VIDEO LAYER */
.video-blok-titel {
  position: absolute;
  top: 15px;
  max-width: 80%;
  padding: 5px 15px;
  color: #fff;
  background: $oeno-blauw;
}

.video-play-button {
  position: absolute;
  z-index: 3;
  bottom: 2rem;
  right: 3.5rem;
  display: block;
  padding: 1.4rem 2rem !important;
}

.video-play-button:hover:after {
  background-color: darken($oeno-oranje, 10%);
}

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.video-overlay {
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  opacity: 0;
  transition: all ease 500ms;
}

.video-overlay.open {
  position: fixed;
  display: flex;
  z-index: 15;
  opacity: 1;
}

.video-overlay-close {
  position: absolute;
  z-index: 1000;
  top: 15px;
  right: 20px;
  font-size: 36px;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: all 200ms;
}

.video-overlay-close:hover {
  color: #fa183d;
}

.video-overlay iframe {
  position: relative;
  top: 0%;
  left: 0%;
  width: 560px;
}

.rij-yt {
  height: 500px;
  margin-bottom: 15px;
  @include atSmall {
    margin-bottom: unset;
  }
}
/* EINDE VIDEO LAYER */
